import { ReactElement } from 'react';
import CabinetPage from '../../components/Common/CabinetPage';

import UserPreferences from './Scheduling';
import Account from './Account';
import Subscription from './BillingAndUsers';
// import Organization from './Organization';
import AnalyticsTab from './AnalyticsTab';
import CRM from './CRM';
import { SubscriptionDetails, User } from '../../store';
import { BILLING_INTERVAL, PAGE_URL, TIER } from '../../constants';
import { CabTabs, CabIcon } from '@CabComponents';
import { Box, Snackbar, styled, Typography } from '@mui/material';
import { Organization as OrganizationType } from "../../store";
import { IoLockClosedOutline } from 'react-icons/io5';
import SupportMapContainer from './SupportMap';


const settingsSubPages = (user?: User | null): { [key: string]: number } => ({
  preferences: 0,
  analytics: 1,
  crm: 2,
  "support-map": 3,
  team: 4,
  account: 5,
});

interface Props {
  subPage?: string;
  user: User | null | undefined;
  organization: OrganizationType | null;
  paymentButtonDisabled: boolean;
  paymentDialogError: string;
  paymentDialogMessage: string;
  paymentRedirectDialogOpen: boolean;
  toastMessage: string;
  clearPaymentDialogError: () => void;
  onCloseToastMessage: () => void;
  openUpgradeModal: () => void
  onChangeSubscription: (
    tier: TIER | null, interval: BILLING_INTERVAL | null, quantity: number | null,
    newPromoCode: string | null, isPreview: boolean, prorationDate: string | null
  ) => Promise<SubscriptionDetails | undefined>
}

const StyledTab = styled("div", { label: "StyledTab" })({
  height: "calc(100vh - 102px)",
});

const AccountStyledTab = styled("div", { label: "AccountStyledTab" })({
  height: "calc(100vh - 142px)",
  overflow: "auto"
});


const Settings = ({
  subPage, user, organization, paymentButtonDisabled, paymentDialogError, paymentDialogMessage, 
  paymentRedirectDialogOpen, toastMessage, onCloseToastMessage, onChangeSubscription, clearPaymentDialogError, 
  openUpgradeModal,
}: Props): ReactElement => {
  const tabValue = (subPage && settingsSubPages(user)[subPage]) || 0;

  return (
    <CabinetPage
      pageName={'Settings'}
      headerContent={
        <Typography variant="h1">
          Settings
        </Typography>
      }
    >
      <CabTabs
        initValue={tabValue}
        tabs={[
          { label: 'Scheduling', onClick: () => window.history.replaceState({}, '', `${PAGE_URL.PREFERENCES}`) },
          {
            label: (
              <Box display="flex" flexDirection="row" gap={1}>
                Analytics
                {(!user?.features.CALENDAR_ANALYTICS) && (
                  <CabIcon Icon={IoLockClosedOutline} />
                )}
              </Box>
            ),
            disabled: !user?.features.CALENDAR_ANALYTICS || !user?.permissions.ANALYTICS_LABELS,
            onClick: () => window.history.replaceState({}, '', `${PAGE_URL.ANALYTICS_TAB}`),
            hidden: user?.features.BLOCK_CALENDAR_ANALYTICS,
          },
          {
            label: (
              <Box display="flex" flexDirection="row" gap={1}>
                People
                {(!user?.features.CRM) && (
                  <CabIcon Icon={IoLockClosedOutline} />
                )}
              </Box>
            ),
            disabled: !user?.features.CRM,
            onClick: () => window.history.replaceState({}, '', `${PAGE_URL.CRM_SETTINGS}`),
            hidden: !user?.features.CRM,
          },
          {
            label: (
              <Box display="flex" flexDirection="row" gap={1}>
                Support Map
                {(!user?.features.SUPPORT_MAP) && (
                  <CabIcon Icon={IoLockClosedOutline} />
                )}
              </Box>
              
            ),
            onClick: () => user?.features.SUPPORT_MAP ? 
              window.history.replaceState({}, '', `${PAGE_URL.SUPPORT_MAP}`)
              :
              openUpgradeModal()
            ,
            hidden: !organization || !user?.permissions.READ_LEADER_DATA_ACCESS || !user?.features.COLLABORATION
          },
          { 
            label: 'Team and Billing',
            onClick: () => window.history.replaceState({}, '', `${PAGE_URL.MY_TEAM}`),
            hidden: !organization || !user?.permissions.STRIPE_SUBSCRIPTION_MGMT
          },
          { label: 'My Account', onClick: () => window.history.replaceState({}, '', `${PAGE_URL.ACCOUNT}`) },
        ]}
      >
        <StyledTab>
          <UserPreferences />
        </StyledTab>
        <StyledTab>
          <AnalyticsTab />
        </StyledTab>
        <StyledTab>
          <CRM />
        </StyledTab>
        <StyledTab>
          <SupportMapContainer />
        </StyledTab>
        <StyledTab>
          <Subscription
            onChangeSubscription={onChangeSubscription}
            clearPaymentDialogError={clearPaymentDialogError}
            paymentButtonDisabled={paymentButtonDisabled}
            paymentDialogError={paymentDialogError}
            paymentDialogMessage={paymentDialogMessage}
            paymentRedirectDialogOpen={paymentRedirectDialogOpen}
          />
        </StyledTab>
        <AccountStyledTab>
          <Account />
        </AccountStyledTab>
      </CabTabs>
      <Snackbar
        open={!!toastMessage}
        onClose={onCloseToastMessage}
        autoHideDuration={5000}
        message={toastMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          marginTop: "64px",
        }}
      />
    </CabinetPage>
  );
};

export default Settings;
