import { ReactElement, useEffect, useState } from 'react';
import { Card, CardContent, Box, Divider, Stack, Typography } from '@mui/material';
import { 
  CabDropdown, CabIcon, CabSwitch, CabTimezoneInput, CabDurationPicker, CabButton, CabTooltip, 
  CabTextInput
} from '@CabComponents';
import {
  SchedulingPreferencesResponse, SchedulingPreferences, User, ZoomSettings, PresetLocations, EventHoldTitle,
  EventHoldDescription,
  WeekStartDay
} from '../../../store';
import { SLOT_INTERVAL_LABELS } from '../../../constants';
import { CabDurationUnitPicker } from '@CabComponents/CabDurationUnitPicker';
import CabMeetingLocationDropdown from '@CabComponents/CabMeetingLocationDropdown';
import { IoAdd, IoClose, IoLockClosedOutline } from 'react-icons/io5';
import { useDebounce } from 'use-debounce';
import LocationsContainer from './Locations';


export interface DueOptions {
  DUE_EMAIL: string,
  DUE_PUSH: string,
  NONE: string,
}

export interface DigestOptions {
  DIGEST_EMAIL: string,
  DIGEST_PUSH: string,
  NONE: string,
}

export const DUE_OPTIONS: DueOptions = {
  DUE_EMAIL: 'due_email',
  DUE_PUSH: 'due_push',
  NONE: '',
}; 

export const DIGEST_OPTIONS: DigestOptions = {
  DIGEST_EMAIL: 'digest_email',
  DIGEST_PUSH: 'digest_push',
  NONE: '',
};

export const PREFERENCES = {
  TASK_TIME_TRACK_REMIND: 'task_time_track_remind',
  DIGEST_ALL: 'digest_all',
};

export interface Props {
  user: User | null | undefined;
  schedulingPrefs: SchedulingPreferencesResponse | undefined;
  onSchedulingPrefChange: (pref: Partial<SchedulingPreferences>) => void;
  onUpdateDefaultSecondaryTz: (idx: number, newTz: string | null) => void;
  onAddDefaultSecondaryTz: () => void;
  onRemoveDefaultSecondaryTz: (idx: number) => void;
  openUpgradeModal: () => void;
  presetLocations: PresetLocations;
  zoomSettings: {
    [settingsId: string]: ZoomSettings;
  };
  onAddZoom: () => void;
  hasMicrosoftGrant: boolean;
  hasGoogleGrant: boolean;
}

const MAX_SECONDARY_TZ = 4;
const MAX_TZ_MSG = "You can only have up to 5 timezones at a time.";

export const UserPreferences = ({
  user, schedulingPrefs, onSchedulingPrefChange, openUpgradeModal, onUpdateDefaultSecondaryTz, 
  onAddDefaultSecondaryTz, onRemoveDefaultSecondaryTz, presetLocations, zoomSettings, onAddZoom,
  hasMicrosoftGrant, hasGoogleGrant
}: Props): ReactElement | null => {

  const secondaryTimezones = schedulingPrefs?.user_prefs?.default_secondary_timezone;
  const reachedMaxTz = !!secondaryTimezones && secondaryTimezones.length >= MAX_SECONDARY_TZ;

  const [override, setOverride] = useState(schedulingPrefs?.user_prefs?.meeting_organizer_override);
  const [debouncedOverride] = useDebounce(override, 800);

  const handleOverrideInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    setOverride(value);
  };

  useEffect(() => {
    if (schedulingPrefs?.user_prefs?.id) {
      onSchedulingPrefChange({
        id: schedulingPrefs.user_prefs.id,
        meeting_organizer_override: debouncedOverride
      });
    }
  }, [debouncedOverride, onSchedulingPrefChange, schedulingPrefs?.user_prefs?.id]);

  if (!user) return null;

  return (
    <Stack marginTop={4} marginLeft={{ xs: 2, lg: "auto" }} marginRight={{ xs: 2, lg: "auto" }}
      paddingBottom={12} maxWidth={900} gap={2}
    >
      {schedulingPrefs?.user_prefs && (
        <>
          <Card variant="outlined" sx={{ width: '100%', padding: 1 }}>
            <CardContent>
              <Stack gap={1}>

                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                  Meeting Defaults
                </Typography>

                <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                  justifyContent="space-between" alignItems="center">
                  <Typography>Default Meeting Location</Typography>
                  <Box maxWidth={400} minWidth={300}>
                    <CabMeetingLocationDropdown
                      value={schedulingPrefs.user_prefs.default_conference_provider !== 999
                        ? {
                          conference: { provider: schedulingPrefs.user_prefs.default_conference_provider, leader: null }
                        }
                        : schedulingPrefs.user_prefs.default_location_presets.length > 0 ? {
                          presetLocation: schedulingPrefs.user_prefs.default_location_presets[0]
                        }
                          : { none: true }
                      }
                      onChange={loc => {
                        if (loc.conference || loc.conference === null) {
                          onSchedulingPrefChange({
                            default_conference_provider: loc.conference?.provider || null,
                            default_location_presets: [],
                          });
                        } else if (loc.presetLocation) {
                          onSchedulingPrefChange({
                            default_conference_provider: 999,
                            default_location_presets: [loc.presetLocation]
                          });
                        } else {
                          onSchedulingPrefChange({
                            default_conference_provider: 999,
                            default_location_presets: [],
                          });
                        }
                      }}
                      onBlurOtherField={() => console.log('N/A')}
                      showTokenInputs={false}
                      templateVars={{}}
                      user={user}
                      leaders={{ 
                        leaders: [],
                        orgLeaders: [],
                        orgSharedLeaderGrants: {},
                        orgLeaderCategories: {},
                        loaded: true,
                        orgLeadersLoaded: true
                      }}
                      zoomSettings={zoomSettings}
                      defaultZoomAccountLabel="Auto Select"
                      addUsernameToZoomLabel={false}
                      isOwner={true}
                      presetLocations={presetLocations}
                      meetingRooms={{}}
                      onAddZoom={onAddZoom}
                      disabledLocationPresetIds={[]}
                      disabledMeetingRoomIds={[]}
                      hideOtherOption
                      showCalendarDefaultOption
                      hasMicrosoftGrant={hasMicrosoftGrant}
                      hasGoogleGrant={hasGoogleGrant}
                    />
                  </Box>

                  {/* <CabDropdown
                    placeholder="Calendar Default"
                    value={availableConferenceProviders
                      .find(cp => cp.id === schedulingPrefs.user_prefs?.default_conference_provider)?.id.toString()
                      || ''
                    }
                    onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      default_conference_provider: e.target.value ? Number(e.target.value) : null
                    })}
                    options={[
                      { value: '', label: 'Calendar Default' },
                      ...availableConferenceProviders.map(conferenceProvider => ({
                        value: conferenceProvider.id.toString(),
                        label: conferenceProvider.label
                      }))
                    ]}
                  /> */}
                </Box>

                <Divider />
                <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                  justifyContent="space-between" alignItems="center">
                  <Typography>Default meeting start times will be offered: </Typography>
                  <CabDropdown<number>
                    value={schedulingPrefs.user_prefs.default_slot_time_interval || 0}
                    options={Object.keys(SLOT_INTERVAL_LABELS).map((interval) => ({
                      value: Number(interval),
                      label: SLOT_INTERVAL_LABELS[Number(interval)],
                    }))}
                    onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      default_slot_time_interval: e.target.value ? Number(e.target.value) : null
                    })}
                  />
                </Box>

                <Divider />
                <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                  justifyContent="space-between" alignItems="center">
                  <Typography>Default Meeting Duration</Typography>
                  <CabDurationPicker
                    durationMinutes={schedulingPrefs.user_prefs.default_duration_minutes}
                    onChange={(minutes) => schedulingPrefs.user_prefs && minutes && onSchedulingPrefChange({
                      id: schedulingPrefs.user_prefs.id,
                      default_duration_minutes: minutes,
                    })}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                  />
                </Box>

                <Divider />
                <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                  justifyContent="space-between" alignItems="center">
                  <Typography>Default notice for new meetings</Typography>
                  <Box display='flex' alignItems='center'
                    onClick={!user?.features.ADVANCE_NOTICE ? openUpgradeModal : undefined}
                  >
                    {!user?.features.ADVANCE_NOTICE  && (
                      <CabIcon
                        Icon={IoLockClosedOutline}
                        alt='Locked'
                        slot='end'
                        sx={{ fontSize: 25, marginRight: 1 }}
                      />
                    )}
                    <CabDurationUnitPicker
                      durationMinutes={schedulingPrefs.user_prefs.default_start_delay_minutes}
                      onChange={(minutes) => schedulingPrefs.user_prefs && minutes >= 0 && onSchedulingPrefChange({
                        id: schedulingPrefs.user_prefs.id,
                        default_start_delay_minutes: minutes,
                      })}
                      disabled={!user?.features.ADVANCE_NOTICE}
                      sx={{ marginTop: 1, marginBottom: 1 }}
                    />
                  </Box>
                </Box>

                {user?.features.PARTICIPANT_REMINDERS && (
                  <>
                    <Divider />
                    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                      <Typography>Send reminder emails to all attendees before meeting</Typography>
                      <CabSwitch
                        checked={schedulingPrefs.user_prefs.send_participant_reminders}
                        onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                          id: schedulingPrefs.user_prefs.id,
                          send_participant_reminders: e.target.checked,
                        })}
                      />
                    </Box>

                    {schedulingPrefs.user_prefs.send_participant_reminders && (
                      <>
                        <Divider />
                        <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                          justifyContent="space-between" alignItems="center">
                          <Typography>Default time before meeting starts to send reminder emails</Typography>
                          <CabDurationUnitPicker
                            durationMinutes={schedulingPrefs.user_prefs.default_participant_reminder_minutes}
                            onChange={(mins) => schedulingPrefs.user_prefs && mins >= 0 && onSchedulingPrefChange({
                              id: schedulingPrefs.user_prefs.id,
                              default_participant_reminder_minutes: mins,
                            })}
                            sx={{ marginTop: 1, marginBottom: 1 }}
                          />
                        </Box>
                      </>
                    )}
                  </>
                )}

                {/*user?.features.BUFFER_TIME && (
                  <>
                    <Divider />
                    <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                      justifyContent="space-between" alignItems="center">
                      <Typography>Default buffer time before meetings</Typography>
                      
                      <CabDurationUnitPicker
                        durationMinutes={schedulingPrefs.user_prefs.default_buffer_start_minutes}
                        onChange={(minutes) => schedulingPrefs.user_prefs && minutes >= 0 && onSchedulingPrefChange({
                          id: schedulingPrefs.user_prefs.id,
                          default_buffer_start_minutes: minutes,
                        })}
                        sx={{ marginTop: 1, marginBottom: 1 }}
                      />
                    </Box>

                    <Divider />
                    <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                      justifyContent="space-between" alignItems="center">
                      <Typography>Default buffer time after meetings</Typography>
                      
                      <CabDurationUnitPicker
                        durationMinutes={schedulingPrefs.user_prefs.default_buffer_end_minutes}
                        onChange={(minutes) => schedulingPrefs.user_prefs && minutes >= 0 && onSchedulingPrefChange({
                          id: schedulingPrefs.user_prefs.id,
                          default_buffer_end_minutes: minutes,
                        })}
                        sx={{ marginTop: 1, marginBottom: 1 }}
                      />
                    </Box>
                  </>
                      )*/}

                <Divider />
                <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                  justifyContent="space-between" alignItems="center">
                  <Typography>Title of calendar holds for Reusable Meetings</Typography>
                  <CabDropdown<EventHoldTitle>
                    value={schedulingPrefs.user_prefs.event_title_for_holds || EventHoldTitle.EVENT_TITLE}
                    options={[
                      { value: EventHoldTitle.INTERNAL_LABEL, label: "Internal Label" },
                      { value: EventHoldTitle.EVENT_TITLE, label: "Event Title" },
                    ]}
                    onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      event_title_for_holds: e.target.value as EventHoldTitle
                    })}
                  />
                </Box>

                <Divider />
                <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                  justifyContent="space-between" alignItems="center">
                  <Typography>Description of calendar holds</Typography>
                  <Box display='flex' alignItems='center'
                    onClick={!user?.features.HOLD_DESCRIPTION_PREFERENCE ? openUpgradeModal : undefined}
                  >
                    {!user?.features.HOLD_DESCRIPTION_PREFERENCE  && (
                      <CabIcon
                        Icon={IoLockClosedOutline}
                        alt='Locked'
                        slot='end'
                        sx={{ fontSize: 25, marginRight: 1 }}
                      />
                    )}
                    <CabDropdown<EventHoldDescription>
                      value={schedulingPrefs.user_prefs.event_description_for_holds || 
                          EventHoldDescription.EVENT_DESCRIPTION}
                      options={[
                        { value: EventHoldDescription.INTERNAL_NOTES, label: "Internal Notes" },
                        { value: EventHoldDescription.EVENT_DESCRIPTION, label: "Event Description" },
                      ]}
                      disabled={!user?.features.HOLD_DESCRIPTION_PREFERENCE}
                      onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                        event_description_for_holds: e.target.value as EventHoldDescription
                      })}
                    />
                  </Box>
                </Box>

                <Divider />
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>Set Automatic Holds as 'Busy' on calendar</Typography>
                  <CabSwitch
                    checked={schedulingPrefs.user_prefs.meeting_holds_busy}
                    onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      id: schedulingPrefs.user_prefs.id,
                      meeting_holds_busy: e.target.checked,
                    })}
                  />
                </Box>

                <Divider />
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>Prevent conflicts for new meetings</Typography>
                  <CabSwitch
                    checked={schedulingPrefs.user_prefs.booking_check_conflicts}
                    onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      id: schedulingPrefs.user_prefs.id,
                      booking_check_conflicts: e.target.checked,
                    })}
                  />
                </Box>

                <Divider />
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>Turn on "Condense Times" by default when sharing availability</Typography>
                  <CabSwitch
                    checked={schedulingPrefs.user_prefs.default_condense_slots}
                    onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      id: schedulingPrefs.user_prefs.id,
                      default_condense_slots: e.target.checked,
                    })}
                  />
                </Box>

                <Divider />
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>Allow respondents to add participants to new meetings</Typography>
                  <CabSwitch
                    checked={schedulingPrefs.user_prefs.default_allow_add_participants}
                    onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      id: schedulingPrefs.user_prefs.id,
                      default_allow_add_participants: e.target.checked,
                    })}
                  />
                </Box>

                <Divider />
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>Include reschedule & cancel links in event descriptions</Typography>
                  <CabSwitch
                    checked={schedulingPrefs.user_prefs.allow_reschedule_cancel}
                    onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      id: schedulingPrefs.user_prefs.id,
                      allow_reschedule_cancel: e.target.checked,
                    })}
                  />
                </Box>

                <Divider />
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>Include questions and answers in event descriptions</Typography>
                  <CabSwitch
                    checked={schedulingPrefs.user_prefs.show_questions_in_body}
                    onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      id: schedulingPrefs.user_prefs.id,
                      show_questions_in_body: e.target.checked,
                    })}
                  />
                </Box>

                <>
                  <Divider />
                  <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                    <Typography>Hide Cabinet marketing message in meeting invite body</Typography>
                    <Box display='flex'>
                      {!user?.features.HIDE_PROMO && (
                        <CabIcon
                          Icon={IoLockClosedOutline}
                          alt='Locked'
                          slot='end'
                          sx={{ fontSize: 25, paddingTop: '4px' }}
                        />
                      )}
                      <CabSwitch
                        checked={schedulingPrefs.user_prefs.hide_promo_text}
                        onChange={ user?.features.HIDE_PROMO ?
                          (e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                            id: schedulingPrefs.user_prefs.id,
                            hide_promo_text: e.target.checked,
                          })
                          : openUpgradeModal
                        }
                      />
                    </Box>
                  </Box>
                </>
              </Stack>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ width: '100%', padding: 1 }}>
            <CardContent>
              <Stack gap={1}>

                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                  Calendar Defaults
                </Typography>

                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>Granular time selection</Typography>
                  <Box display='flex'>
                    <CabSwitch
                      checked={schedulingPrefs.user_prefs.granular_time_selection}
                      onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                        id: schedulingPrefs.user_prefs.id,
                        granular_time_selection: e.target.checked,
                      })}
                    />
                  </Box>
                </Box>

                <Divider />
                <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                  justifyContent="space-between" alignItems="center">
                  <Typography>Start of the week</Typography>
                  <Box display='flex' alignItems='center'>
                    <CabDropdown<WeekStartDay>
                      value={schedulingPrefs.user_prefs.week_start_day || WeekStartDay.SUNDAY}
                      options={[
                        { value: WeekStartDay.SUNDAY, label: "Sunday" },
                        { value: WeekStartDay.MONDAY, label: "Monday" },
                      ]}
                      onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                        week_start_day: e.target.value as WeekStartDay
                      })}
                    />
                  </Box>
                </Box>

                <Divider />
                <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                  justifyContent="space-between" alignItems="center">
                  <Typography>Primary calendar timezone</Typography>
                  <CabTimezoneInput
                    alignRight
                    value={schedulingPrefs.user_prefs.default_calendar_timezone || "null" }
                    sx={{width: 300}}
                    onChange={(value) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      id: schedulingPrefs.user_prefs.id,
                      default_calendar_timezone: value === "null" ? null : value,
                    })}
                    additionalOptions={[{
                      value: 'null',
                      label: "Automatic (my location)",
                      meta: {
                        allAbbreviations: [],
                        mainCities: [],
                        group: "automatic"
                      }
                    }]}
                  />
                </Box>
                
                <>
                  <Divider />
                  <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                    <Typography>Display multiple timezones</Typography>
                    <Box display='flex'>
                      {!user?.features.MULTI_TIMEZONE && (
                        <CabIcon
                          Icon={IoLockClosedOutline}
                          alt='Locked'
                          slot='end'
                          sx={{ fontSize: 25, paddingTop: '4px' }}
                        />
                      )}
                      <CabSwitch
                        checked={schedulingPrefs.user_prefs.multi_timezone}
                        onChange={ user?.features.MULTI_TIMEZONE ?
                          (e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                            id: schedulingPrefs.user_prefs.id,
                            multi_timezone: e.target.checked,
                          })
                          : openUpgradeModal
                        }
                      />
                    </Box>
                  </Box>
                  {secondaryTimezones?.map((tz, i) => (
                    <Box key={i}>
                      <Divider />
                      <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                        justifyContent="space-between" alignItems="center" paddingTop={1}>
                        <Typography>Additional timezone</Typography>
                        <Box display='flex'>
                          {!user?.features.MULTI_TIMEZONE && (
                            <CabIcon
                              Icon={IoLockClosedOutline}
                              alt='Locked'
                              slot='end'
                              sx={{ fontSize: 25, paddingTop: '6px', marginRight: '4px' }}
                            />
                          )}
                          <CabTimezoneInput
                            alignRight
                            value={tz || "null" }
                            sx={{width: 300}}
                            onChange={(newTz) => onUpdateDefaultSecondaryTz(i, newTz)}
                            disabled={!schedulingPrefs.user_prefs?.multi_timezone}
                            additionalOptions={[{
                              value: 'null',
                              label: "Automatic (my location)",
                              meta: {
                                allAbbreviations: [],
                                mainCities: [],
                                group: "automatic"
                              }
                            }]}
                          />
                        
                          <CabIcon
                            Icon={IoClose}
                            onClick={() => onRemoveDefaultSecondaryTz(i)}
                            sx={{ marginLeft: 1 }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                  <Box>
                    <CabTooltip title={reachedMaxTz ? MAX_TZ_MSG : ''} wrapWithSpan>
                      <CabButton
                        buttonType="tertiary"
                        onClick={onAddDefaultSecondaryTz}
                        sx={{ width: 190 }}
                        icon={<CabIcon Icon={IoAdd} />}
                        disabled={!schedulingPrefs.user_prefs?.multi_timezone || reachedMaxTz}
                      >
                        Add Timezone
                      </CabButton>
                    </CabTooltip>
                  </Box>
                </>
              </Stack>
            </CardContent>
          </Card>
          {user.features.MEETING_ORGANIZER_OVERRIDE && (
            <Card variant="outlined" sx={{ width: '100%', padding: 1 }}>
              <CardContent>
                <Stack gap={1}>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                    Booking Page
                  </Typography>

                  <Box width="100%" display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                    justifyContent="space-between" alignItems="center">
                    <Typography>Meeting organizer shown on booking pages</Typography>
                    <CabTextInput
                      placeholder={`${user.first_name} ${user.last_name}`}
                      value={override}
                      onChange={handleOverrideInputChange}
                      sx={{ width: 300 }}
                    />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          )}
        </>
      )}
      
      <LocationsContainer />
      
      <Card variant="outlined" sx={{ width: '100%', padding: 1 }}>
        <CardContent>
          <Stack gap={1}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
              Notifications
            </Typography>

            {schedulingPrefs?.user_prefs && (
              <>
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>Warn me when I enable calendar holds</Typography>
                  <CabSwitch
                    checked={!schedulingPrefs.user_prefs.silence_executive_hold_notice}
                    onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      id: schedulingPrefs.user_prefs.id,
                      silence_executive_hold_notice: !e.target.checked,
                    })}
                  />
                </Box>

                <Divider />
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>Notify me when a respondent fills out a poll</Typography>
                  <CabSwitch
                    checked={!schedulingPrefs.user_prefs.disable_poll_response_confirmation}
                    onChange={(e) => schedulingPrefs.user_prefs && onSchedulingPrefChange({
                      id: schedulingPrefs.user_prefs.id,
                      disable_poll_response_confirmation: !e.target.checked,
                    })}
                  />
                </Box>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>

    </Stack>
  );
};

export default UserPreferences;
