import { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  AccountOwner, FetchReturn, Organization as OrgType,
  OrganizationLicense, OrganizationSubmission, SubscriptionDetails, ThunkDispatchType, User, actions
} from '../../../../store';
import { Typography, Grid } from '@mui/material';
import OrganizationLicenseContainer, {
  UpdateOrganizationLicense
} from '../../../../components/Settings/OrganizationLicense';
import { BILLING_INTERVAL, TIER } from '../../../../constants';
import { GridData } from '../../../../components/Settings/OrganizationLicense/types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';


interface Props {
  org: OrganizationSubmission;
  updateOrganization: (org: OrganizationSubmission) => Promise<FetchReturn<OrgType> | undefined>;
  processOrganizationLogo: (org: OrganizationSubmission) => Promise<FetchReturn<{ data: string }>>;
  accountOwner: AccountOwner | undefined;
  user: User | undefined | null;
  subscriptionDetails: SubscriptionDetails | null;
  confirmButtonDisabled: boolean;
  dialogError: string;
  dialogMessage: string;
  redirectDialogOpen: boolean;
  clearPaymentDialogError: () => void;
  resendInvite: (license: OrganizationLicense) => void;
  onChangeSubscription: (
    newTier: TIER | null, interval: BILLING_INTERVAL | null, quantity: number | null,
    newPromoCode: string | null, isPreview: boolean, prorationDate: string | null
  ) => Promise<SubscriptionDetails | undefined>;
}

export const Organization = ({
  org, resendInvite
}: Props): ReactElement => {
  const [usedStarterLicenses, setUsedStarterLicenses] = useState(0);
  const [usedIndividualLicenses, setUsedIndividualLicenses] = useState(0);
  const [usedGrowthLicenses, setUsedGrowthLicenses] = useState(0);
  const [usedPremierLicenses, setUsedPremierLicenses] = useState(0);
  const [/*quantityDialogOpen*/, setQuantityDialogOpen] = useState(false);
  const [openDeletionModal, setOpenDeletionModal] = useState(false);
  const [openChangesModal, setOpenChangesModal] = useState(false);

  const {
    control, getValues, formState, setValue, handleSubmit, trigger, reset
  } = useForm<GridData>({ defaultValues: { data: org.licenses || {} }, reValidateMode: 'onBlur' });

  const [createdRows, setCreatedRows] = useState<GridData["data"] | null>(null);
  const [updatedRows, setUpdatedRows] = useState<UpdateOrganizationLicense>(null);
  const [deletedRows, setDeletedRows] = useState<GridData["data"] | null>(null);
  const [rows, setRows] = useState<GridData['data']>(getValues("data"));
  const [/*cachedRows*/, setCachedRows] = useState<GridData['data']>(getValues("data"));

  const handleSetUsedStarter = (used: number) => {
    setUsedStarterLicenses(used);
  };

  const handleSetUsedIndividual = (used: number) => {
    setUsedIndividualLicenses(used);
  };

  const handleSetUsedGrowth = (used: number) => {
    setUsedGrowthLicenses(used);
  };

  const handleSetUsedPremier = (used: number) => {
    setUsedPremierLicenses(used);
  };

  const dispatch = useDispatch<ThunkDispatchType>();

  const modalPopUpLogic = useCallback((
    passedUpdatedRows: UpdateOrganizationLicense,
    passedCreatedRows: GridData["data"] | null,
    passedDeletedRows: GridData["data"] | null,
    curOpenChangesModal: boolean
  ) => {
    if (Object.values(passedDeletedRows || {}).length > 0 && !curOpenChangesModal) {
      setOpenDeletionModal(true);
    } else if (Object.values(passedCreatedRows || {}).length > 0 || Object.values(passedUpdatedRows || {}).length > 0) {
      setOpenChangesModal(true);
    }
  }, []);

  useEffect(() => {
    setRows(org.licenses || {});
    reset({ data: org.licenses || {} });
  }, [org.licenses, reset]);

  const submitTableSuccess = useCallback(async (data: GridData, openModal: boolean) => {
    const tempCreatedRows: GridData["data"] = {};
    const tempUpdatedRows: { [id: number]: Partial<OrganizationLicense> } = {};
    const tempDeletedRows: GridData["data"] = {};

    if (org.licenses) {
      Object.entries(data["data"]).forEach(([id, licenseData]) => {
        if (licenseData.id < 0) {
          tempCreatedRows[licenseData.id] = licenseData;
        } else if (licenseData.id === 0) {
          tempDeletedRows[Number(id)] = { ...licenseData, id: Number(id) };
        }
      });
    }
    if (formState.dirtyFields.data !== undefined) {
      Object.entries(formState.dirtyFields.data).forEach(
        ([key, value]) => {
          if (tempCreatedRows[Number(key)] === undefined && tempDeletedRows[Number(key)] === undefined) {
            tempUpdatedRows[Number(key)] = data["data"][Number(key)];
          }
        });
    }

    setCreatedRows(tempCreatedRows);
    setUpdatedRows(tempUpdatedRows);
    setDeletedRows(tempDeletedRows);
    if (openModal) {
      modalPopUpLogic(tempUpdatedRows, tempCreatedRows, tempDeletedRows, openChangesModal);
    }
  }, [formState.dirtyFields.data, modalPopUpLogic, openChangesModal, org.licenses]);

  const submitTable = useCallback(async () => {
    const valid = await trigger();
    if (valid) {
      handleSubmit((data) => submitTableSuccess(data, true))();
    }
  }, [handleSubmit, submitTableSuccess, trigger]);

  const handleOpenLicenseModal = useCallback(async () => {
    setQuantityDialogOpen(true);
    const valid = await trigger();
    if (valid) {
      handleSubmit((data) => submitTableSuccess(data, false))();
    }
  }, [handleSubmit, submitTableSuccess, trigger]);

  const confirmedChanges = useCallback(async (
    newCreatedRows: GridData["data"] | null,
    newUpdatedRows: UpdateOrganizationLicense | null,
    newDeletedRows: GridData["data"] | null,
    currentRows: GridData["data"]
  ) => {

    const createdLicenses = (newCreatedRows && Object.keys(newCreatedRows).length > 0) ?
      (await dispatch(actions.organization.createOrganizationLicenses(newCreatedRows)))
      : null;
    const updatedLicenses = (newUpdatedRows && Object.keys(newUpdatedRows).length > 0) ?
      (await dispatch(actions.organization.bulkUpdateOrganizationLicenses(newUpdatedRows)))
      : null;
    const deletedLicenses = (newDeletedRows && Object.keys(newDeletedRows).length > 0) ?
      (await dispatch(actions.organization.deleteOrganizationLicenses(Object.keys(newDeletedRows).map(Number))))
      : null;
    let newRows = { ...currentRows };
    if (createdLicenses && createdLicenses.status === 201) {
      newRows = { ...createdLicenses.data.created, ...newRows };
    }

    if (updatedLicenses && updatedLicenses.status === 200) {
      newRows = { ...newRows, ...updatedLicenses.data.updated };
    }

    if (deletedLicenses && deletedLicenses.status === 200) {
      deletedLicenses.data.deleted.forEach(id => {
        if (newRows[id]) delete newRows[id];
      });
    }

    const cleanCreated = { ...newRows };
    Object.values(newRows).forEach((newRowEntry) => {
      if (newRowEntry.id < 0) {
        delete cleanCreated[newRowEntry.id];
      }
    });
    setRows(cleanCreated);
    reset({ data: cleanCreated });
    setCreatedRows(null);
    setUpdatedRows(null);
    setDeletedRows(null);
    setOpenChangesModal(false);
  }, [dispatch, reset]);

  const canceledChanges = () => {
    setCreatedRows(null);
    setUpdatedRows(null);
    setDeletedRows(null);
    setOpenChangesModal(false);
  };

  const handleSetRows = (value: GridData["data"]) => {
    setRows(value);
  };

  const discardChanges = useCallback(() => {
    reset({ data: org.licenses || {} });
    if (org.licenses) {
      setRows(org.licenses);
    }
  }, [org.licenses, reset]);

  const canceledDeletions = () => {
    const dataRows = { ...rows };

    Object.values(deletedRows || {}).forEach((datum) => {
      dataRows[datum.id] = datum;
    });
    reset({ data: dataRows });
    Object.values(updatedRows || {}).forEach((row) => {
      const key = `data.${row.id}.email`;
      setValue(key, row.email, { shouldDirty: true });
    });
    setRows(dataRows);
    setDeletedRows(null);
    setOpenDeletionModal(false);
  };

  const confirmedDeletions = () => {
    setOpenDeletionModal(false);
    setOpenChangesModal(true);
  };

  const handleCreateRow = async (licenseData: OrganizationLicense) => {
    const tempCreatedRows: GridData["data"] = {};

    if (org.licenses) {
      if (licenseData.id < 0) {
        tempCreatedRows[licenseData.id] = licenseData;
      }
    }
    setValue("data", { ...getValues("data"), ...tempCreatedRows }, { shouldDirty: false });
    setCreatedRows(tempCreatedRows);
    setOpenChangesModal(true);
  };

  return (
    <Grid item xs={12} md={12}>
      <Typography variant="h1" sx={{ marginBottom: 1 }}>Users</Typography>
      <OrganizationLicenseContainer
        organization={org}
        usedStarterLicenses={usedStarterLicenses}
        usedIndividualLicenses={usedIndividualLicenses}
        usedGrowthLicenses={usedGrowthLicenses}
        usedPremierLicenses={usedPremierLicenses}
        setUsedIndividual={handleSetUsedIndividual}
        setUsedStarter={handleSetUsedStarter}
        setUsedGrowth={handleSetUsedGrowth}
        setUsedPremier={handleSetUsedPremier}
        handleOpenLicenseModal={handleOpenLicenseModal}
        createdRows={createdRows}
        updatedRows={updatedRows}
        deletedRows={deletedRows}
        canceledChanges={canceledChanges}
        canceledDeletions={canceledDeletions}
        confirmedChanges={confirmedChanges}
        confirmedDeletions={confirmedDeletions}
        setRows={handleSetRows}
        control={control}
        setValue={setValue}
        getValues={getValues}
        handleCreateRow={handleCreateRow}
        formState={formState}
        reset={reset}
        submitTable={submitTable}
        openDeletionModal={openDeletionModal}
        openChangesModal={openChangesModal}
        rows={rows || {}}
        discardChanges={discardChanges}
        setCachedRows={(value) => setCachedRows(value)}
        org={org}
        resendInvite={resendInvite}
      />
    </Grid>
  );
};

export default Organization;
