import { ReactElement, useState } from 'react';
import Settings from './Settings';

import { actions, FetchReturn, GlobalModalComponentName, 
  RootState, SubscriptionDetails, ThunkDispatchType } from '../../store';
import { getCheckoutSessionId, redirectToCheckout } from '../../utils/paymentUtils';

import { useMountEffect } from '../../utils/hooks';
import { BILLING_INTERVAL, NEW_TIER, TIER } from '../../constants';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { cabCaptureException } from '../../utils/logging';


type RouteParams = { subPage?: string; };


export const SettingsContainer = (): ReactElement => {
  const user = useSelector((state: RootState) => state.auth.user);
  const organization = useSelector((state: RootState) => state.organization);

  const dispatch = useDispatch<ThunkDispatchType>();

  const fetchSubscriptionDetails = () => dispatch(actions.organization.fetchSubscriptionDetails());
  const changeSubscription = (
    newTier: TIER | null, newInterval: BILLING_INTERVAL | null, newQuantity: number | null,
    newPromoCode: string | null, isPreview?: boolean, prorationDate?: string | null
  ) => dispatch(actions.organization.changeSubscription(
    newTier, newInterval, newQuantity,
    newPromoCode, isPreview, prorationDate
  ));

  const openUpgradeModal = () => {
    dispatch(actions.globalModal.openModal(GlobalModalComponentName.CABINET_PROMO));
  };

  const [paymentButtonDisabled, setPaymentButtonDisabled] = useState(false);
  const [paymentDialogError, setPaymentDialogError] = useState('');
  const [paymentDialogMessage, setPaymentDialogMessage] = useState('');
  const [paymentRedirectDialogOpen, setPaymentRedirectDialogOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const params = useParams<RouteParams>();

  useMountEffect(() => {
    fetchSubscriptionDetails();
  });

  const handlePaymentStartSuccess = (sessionId: string): void => {
    setPaymentRedirectDialogOpen(true);
    setTimeout(() => {
      redirectToCheckout(sessionId);
    }, 3000);
  };

  const handleChangeSubscriptionSuccess = (): void => {
    setToastMessage('Your subscription has been successfully changed!');
    setPaymentButtonDisabled(false);
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  const handlePaymentStartFailed = (res?: FetchReturn): void => {
    setPaymentButtonDisabled(false);
    if (res?.status === 400 && res.data.code !== 'unknown' && res.data.detail) {
      setPaymentDialogError(res.data.detail);
    } else {
      setPaymentDialogError(`Uh oh - Something went wrong! Try again \
                      or send an SOS to our team at help@joincabinet.com.`);
    }
  };

  const handleChangeSubscription = async (
    tier: TIER | null, interval: BILLING_INTERVAL | null, quantity: number | null, 
    newPromoCode: string | null, isPreview: boolean, prorationDate: string | null
  ): Promise<SubscriptionDetails | undefined> => {

    setPaymentButtonDisabled(true);
    let error: Error | null = null;

    // NOTE: Right now we don't handle coupon codes 
    if (tier !== NEW_TIER.BASIC) {
      try {
        const session = await getCheckoutSessionId(
          '', tier, interval, quantity, window.location.href
        );
        if (session?.status === 200) {
          if (!session.data.active_sub_id && session.data.session_id) {
            // If the user doesn't have an active sub, just start Stripe flow
            //     even if isPreview is true
            handlePaymentStartSuccess(session.data.session_id);
  
            // Need to return here so we don't assume there's an error at the end of this function
            return;
          } else if (session.data.active_sub_id) {
            const res = await changeSubscription(tier, interval, quantity, newPromoCode, isPreview, prorationDate);
            if (res && res.status === 200) {
              setPaymentDialogError("");
              if (newPromoCode) {
                setPaymentDialogMessage(`Promo code "${newPromoCode}" applied!`);
              } else {
                setPaymentDialogMessage("");
              }
              if (isPreview) {
                setPaymentButtonDisabled(false);
              } else {
                setPaymentDialogError("");
                // Don't declare the subscription changed and refresh the page
                //    if this is just the preview
                handleChangeSubscriptionSuccess();
              }
              return res.data;
            } else {
              const resStatus = res?.status;
              const resData = (res && 'data' in res) ? res.data : {};
              error = Error(
                `Subscription Change Failed: Bad Response from ChangeSubscription: 
                (${resStatus}) - ${JSON.stringify(resData)}`
              );
              handlePaymentStartFailed(res);
            }
          } else {
            error = Error(
              `Subscription Change Failed: Missing Active Sub ID and Session ID`
            );
            handlePaymentStartFailed();
          }
        } else if (session?.status === 400) {
          handlePaymentStartFailed(session);
          return undefined;
        } else {
          error = Error(
            `Subscription Change Failed: No Session Generated`
          );
        }
      } catch (e) {
        error = e as Error;
        handlePaymentStartFailed();
      }
    } else {
      const res = await changeSubscription(tier, interval, quantity, newPromoCode, isPreview, prorationDate);
      if (res?.status === 200) {
        return res.data;
      }
    }

    if (!error) {
      error = Error("Subscription Change Failed: Reason Unknown");
    }
    
    cabCaptureException(error);
    throw error;
  };

  return (
    <Settings 
      subPage={params.subPage} 
      user={user} 
      organization={organization}
      paymentButtonDisabled={paymentButtonDisabled}
      paymentDialogError={paymentDialogError}
      paymentDialogMessage={paymentDialogMessage}
      paymentRedirectDialogOpen={paymentRedirectDialogOpen}
      clearPaymentDialogError={() => {
        setPaymentDialogError("");
        setPaymentButtonDisabled(false);
      }}
      toastMessage={toastMessage}
      onCloseToastMessage={() => setToastMessage('')}
      onChangeSubscription={handleChangeSubscription}
      openUpgradeModal={openUpgradeModal}
    />
  ); 
};

export default SettingsContainer;
