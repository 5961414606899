import { ReactElement, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { 
  BILLING_INTERVAL, CABINET_FEATURES_PAGE_URL, EVENT_TYPE, TIER, NEW_TIER, 
  NEW_TIER_DETAILS, NewITier, LARGE_TEAMS
} from '../../../constants';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { AccountOwner, SubscriptionChange, SubscriptionDetails } from '../../../store';
import { trackEventWithExtra } from '../../../utils/appAnalyticsUtils';
import { CabButton } from '@CabComponents/CabButton';
import { Box, styled } from '@mui/material';
import { PopupButton } from '@typeform/embed-react';
import { SubChangeConfirm } from './SubChangeConfirm';
import { SubSummary } from './SubSummary';
import colors from '../../../colors';
import { CabModal } from '@CabComponents/CabModal';
import { DateTime } from 'luxon';
import { CabTabs } from '@CabComponents/CabTabs';
import OrganizationContainer from './Organization';
import UsersContainer from './Users';

interface Props {
  subscriptionDetails: SubscriptionDetails | null;
  accountOwner: AccountOwner | undefined;
  onChangeSubscription: (
    newTier: TIER | null, interval: BILLING_INTERVAL | null, quantity: number | null, 
    newPromoCode: string | null, isPreview: boolean, prorationDate: string | null
  ) => Promise<SubscriptionDetails | undefined>;
  confirmButtonDisabled: boolean;
  dialogError: string;
  dialogMessage: string;
  redirectDialogOpen: boolean;
  onOpenBillingPortal: () => void;
  isStripeManager: boolean;
  cancelationSurveyId: string;
  clearPaymentDialogError: () =>  void;
  isOnContract: boolean
  showLicenseTable: boolean
}


export const Subscription = ({
  subscriptionDetails, accountOwner, onChangeSubscription, confirmButtonDisabled, dialogError, dialogMessage, 
  redirectDialogOpen, onOpenBillingPortal, isStripeManager, cancelationSurveyId, clearPaymentDialogError, isOnContract, 
  showLicenseTable,
}: Props): ReactElement => {

  const [chosenTierKey, setChosenTierKey] = useState<TIER>(NEW_TIER.STARTER);
  const [chosenInterval, setChosenInterval] = useState<BILLING_INTERVAL>(BILLING_INTERVAL.MONTH);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [disableCancelButtons, setDisableCancelButtons] = useState(false);
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  const [newSubscriptionDetails, setNewSubscriptionDetails] = useState<SubscriptionChange | null>(null);
  const [quantityDialogOpen, setQuantityDialogOpen] = useState(false);

  const tabs = [{
    label: 'Summary',
    id: 'summary',
  }, {
    label: 'Users',
    id: 'users',
    disabled: !showLicenseTable,
  }, ...(!isOnContract ? [{
    label: 'Subscription',
    id: 'subscription',
  }] : [])];

  const subWillCancel = subscriptionDetails?.cancel_at && 
      DateTime.fromISO(subscriptionDetails?.cancel_at) > DateTime.now();

  const handleClickTier = (tierKey: TIER) => {
    setChosenTierKey(tierKey);
    if (subWillCancel) {
      handleSetNewSubscriptionDetails();
    } else if (tierKey === NEW_TIER.BASIC) {
      setCancelDialogOpen(true);
    } else {
      const currentInterval = subscriptionDetails?.interval;
      if (currentInterval) {
        setChosenInterval(currentInterval);
      }
      setSubscriptionDialogOpen(true);
    }
    // Note: Code below will allow only 1 change at a time (interval or tier)
    //       I decided to comment it out for now because it makes it impossible for users to 
    //       schedule certain types of changes, and it got really complicated.
    //       e.g. Individual/Monthly to Starter/Annual. You need to switch Individual > Starter first,
    //            but then switching to Annual would set you back to Individual.
    // } else if (tierKey === subscriptionDetails?.tier) {
    //   setChosenInterval(subscriptionDetails.interval);
    //   setSubscriptionDialogOpen(true);
    // } else {
    //   const newInterval = subscriptionDetails?.scheduled_changes?.interval ?? subscriptionDetails?.interval;
    //   if (!newInterval) {
    //     return;
    //   }
    //   handleSetNewSubscriptionDetails(tierKey, newInterval);
    // }
    trackEventWithExtra({
      eventName: EVENT_TYPE.SUBSCRIPTION_TIER_CLICKED,
      extra: {tier: tierKey}
    });
  };

  const handleSetNewSubscriptionDetails = (
    newTier: TIER | null = null, newInterval: BILLING_INTERVAL | null = null
  ) => {
    if (subscriptionDetails) {
      setNewSubscriptionDetails({
        tier: newTier ?? chosenTierKey, 
        interval: newInterval ?? chosenInterval, 
        quantity: null,
        prorationDate: null,
      });
    }
  };

  const handleClearNewSubscriptionDetails = () => {
    setNewSubscriptionDetails(null);
  };

  const handleClickInterval = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChosenInterval(e.target.value as BILLING_INTERVAL);
  };
  
  interface ITierSection {
    tierKey: TIER;
    multiSub: boolean;
  }

  const TierSection = ({tierKey, multiSub}: ITierSection): ReactElement => {
    const {
      title, description, annualPrice, monthlyPrice, selfService
    } = NEW_TIER_DETAILS[tierKey] ? NEW_TIER_DETAILS[tierKey] : {
      title: "",
      description: "",
      annualPrice: 0,
      monthlyPrice: 0,
      visible: false,
      selfService: false
    } as NewITier;
    const selected = tierKey === subscriptionDetails?.tier;
    const individualEnabled = tierKey === NEW_TIER.INDIVIDUAL && !multiSub ;
    const tierEnabled = tierKey !== NEW_TIER.INDIVIDUAL || individualEnabled;
    const buttonText = selfService ? (subWillCancel ? "Don't Cancel" : 
      selected 
        ? tierKey === NEW_TIER.BASIC 
          ? 'Current Plan' 
          : 'Change Interval' 
        : `Select ${title}`) : "Learn More";
    const buttonDisabled = !tierEnabled;
    const buttonVisible = selected || !subWillCancel;
    
    const handleClickLearnMore = () => {
      trackEventWithExtra({
        eventName: EVENT_TYPE.SUBSCRIPTION_LEARN_MORE_CLICKED,
        extra: {tier: tierKey}
      }, () => window.open(CABINET_FEATURES_PAGE_URL, '_blank'));
    };

    const tierButtonClick = () => {
      if (!selfService) {
        window.open(LARGE_TEAMS, '_blank');
      } else if (tierKey !== NEW_TIER.INDIVIDUAL || individualEnabled) {
        handleClickTier(tierKey);
      }
    };

    return (
      <LineItemGrid container spacing={0}>
        <Grid item xs={12} sm={6} md={8} lg={7} xl={8}>
          <Box sx={{fontSize: "18px", fontWeight: "bold"}}>
            {title}
          </Box>
          <Box sx={{fontSize: "14px", paddingRight: "24px"}}>
            {(tierKey === NEW_TIER.INDIVIDUAL && !tierEnabled) &&
              <Box component="div" sx={{fontWeight: "normal", fontSize: 16, marginBottom: 1}}>
                (Downgrade to a single license to continue)
              </Box>
            }
            {description}
          </Box>
          <LinkText 
            variant='body2' 
            onClick={handleClickLearnMore}
          >
            Learn More
          </LinkText>
          <Box height={"12px"}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={5} xl={4} sx={{
          display: "flex", justifyContent: "flex-end", alignItems: "center"
        }}>
          <Box sx={{fontSize: "14px", marginRight: "24px"}}>
            {monthlyPrice > 0 &&
              <div>
                {`$${monthlyPrice}/month`}
              </div>
            }
            {annualPrice > 0 &&
            <div>
              {`$${annualPrice}/year`}
            </div>
            }
          </Box>
          <StyledButton
            buttonType={subscriptionDetails?.cancel_at && selected ? "secondary" : "primary"}
            color={selected ? 'secondary' : 'primary'} 
            disabled={buttonDisabled}
            sx={{visibility: !buttonVisible ? 'hidden' : 'unset'}}
            onClick={() => tierButtonClick()}
          >
            {buttonText}
          </StyledButton>
        </Grid>
      </LineItemGrid>
    );
  };

  const ownerContact = accountOwner 
    ? `(${accountOwner?.first_name} ${accountOwner?.last_name}, ${accountOwner?.email})`
    : null;

  const closeCancelDialog = () => {
    if (disableCancelButtons) {
      setCancelDialogOpen(false);
    }
  };
  
  const multiSub = (subscriptionDetails?.quantity || 0) > 1;

  return (
    <>
      <CabTabs tabs={tabs} tabPanelSx={{ margin: 6 }}>
        <Box display="flex" flexDirection="column" gap={2}>
          <OrganizationContainer 
            onChangeSubscription={onChangeSubscription}
            clearPaymentDialogError={clearPaymentDialogError}
            paymentButtonDisabled={confirmButtonDisabled}
            paymentDialogError={dialogError}
            paymentDialogMessage={dialogMessage}
            paymentRedirectDialogOpen={redirectDialogOpen}
          />
          <Card variant='outlined' sx={{marginBottom: "16px"}}>
            <LineItemGroupTitle>
              Billing
            </LineItemGroupTitle>
            {subscriptionDetails?.is_owner || (isOnContract && isStripeManager)
              ? <>
                <LineItem>
                  <LinkText variant='body1' onClick={onOpenBillingPortal}>
                    Update payment information or download receipts
                  </LinkText>
                </LineItem>
                {(subscriptionDetails && subscriptionDetails.tier !== 'BASIC'
                  && isStripeManager && subscriptionDetails.self_service &&
                  !isOnContract) &&
                  <LineItem>
                    <LinkText variant='body1' onClick={() => setCancelDialogOpen(true)}>
                      Cancel Subscription
                    </LinkText>
                  </LineItem>
                }
              </>
              : <LineItem>
                <Typography variant='body1'>
                  {`To change billing information, contact your account administrator ${ownerContact}`}
                </Typography>
              </LineItem>
            }
          </Card>
        </Box>

        <UsersContainer
          onChangeSubscription={onChangeSubscription}
          clearPaymentDialogError={clearPaymentDialogError}
          paymentButtonDisabled={confirmButtonDisabled}
          paymentDialogError={dialogError}
          paymentDialogMessage={dialogMessage}
          paymentRedirectDialogOpen={redirectDialogOpen}
        />

        {!isOnContract && (
          <Grid container spacing={4}>
            <Grid item xs={1} lg={2}/>
            <Grid item xs={10} lg={8} sx={{marginTop: "32px", padding: 0}}>
              {(isStripeManager && subscriptionDetails?.self_service && !isOnContract) &&
                <Card variant='outlined' sx={{marginBottom: "16px"}}>
                  <LineItemGroupTitle>
                    Plans
                  </LineItemGroupTitle>
                  {Object.keys(NEW_TIER).filter(
                    (tier) => {
                      return NEW_TIER_DETAILS[tier].visible;
                    }
                  ).map(tierKey => <TierSection key={tierKey} tierKey={tierKey as NEW_TIER} multiSub={multiSub}/>)}
                </Card>
              }
              {subscriptionDetails?.tier && 
                <SubSummary 
                  isOnContract={isOnContract}
                  subscriptionDetails={subscriptionDetails}
                  confirmButtonDisabled={confirmButtonDisabled}
                  dialogError={dialogError}
                  dialogMessage={dialogMessage}
                  onChangeSubscription={onChangeSubscription}
                  clearPaymentDialogError={clearPaymentDialogError}
                  isStripeManager={isStripeManager}
                  quantityDialogOpen={quantityDialogOpen}
                  handleQuantityDialogOpen={(value) => setQuantityDialogOpen(value)}
                  onAddAdditionalLicensesCancel={() => null}
                  onLicenseChangeSuccess={() => null}
                />
              }
              {(subscriptionDetails?.has_customer_account) &&
                <Card variant='outlined' sx={{marginBottom: "16px"}}>
                  <LineItemGroupTitle>
                    Billing
                  </LineItemGroupTitle>
                  {subscriptionDetails?.is_owner || (isOnContract && isStripeManager)
                    ? <>
                      <LineItem>
                        <LinkText variant='body1' onClick={onOpenBillingPortal}>
                          Update payment information or download receipts
                        </LinkText>
                      </LineItem>
                      {(subscriptionDetails.tier !== 'BASIC' && isStripeManager && subscriptionDetails.self_service &&
                      !isOnContract) &&
                        <LineItem>
                          <LinkText variant='body1' onClick={() => setCancelDialogOpen(true)}>
                            Cancel Subscription
                          </LinkText>
                        </LineItem>
                      }
                    </>
                    : <LineItem>
                      <Typography variant='body1'>
                        {`To change billing information, contact your account administrator ${ownerContact}`}
                      </Typography>
                    </LineItem>
                  }
                </Card>
              }
            </Grid>
            <Grid item xs={1} lg={2}/>
          </Grid>
        )}
      </CabTabs>

      <SubChangeConfirm
        onClose={handleClearNewSubscriptionDetails}
        buttonDisabled={confirmButtonDisabled}
        dialogError={dialogError}
        dialogMessage={dialogMessage}
        newSubscriptionDetails={newSubscriptionDetails}
        onChangeSubscription={onChangeSubscription}
        onLicenseChangeSuccess={() => null}
      />
      <CabModal 
        open={subscriptionDialogOpen}
        onClose={() => setSubscriptionDialogOpen(false)}
        title="Choose Subscription Options"
        aria-labelledby='Subscription Dialog'
        aria-describedby='Subscription Dialog'
        actionButtons={
          <DialogButtonContainer>
            <StyledButton buttonType='secondary'
              onClick={() => {
                setSubscriptionDialogOpen(false);
                clearPaymentDialogError();
              }}>
              Cancel
            </StyledButton>
            <StyledButton disabled={confirmButtonDisabled}
              onClick={() => handleSetNewSubscriptionDetails()}>
              Continue
            </StyledButton>
          </DialogButtonContainer>
        }
      >
        <div>
          <div>
            {`Selected Plan: ${NEW_TIER_DETAILS[chosenTierKey]?.title}`}
          </div>
          <FormControl sx={{marginTop: "16px", display: "flex", justifyContent: "flex-start"}}>
            <RadioGroup row defaultValue={BILLING_INTERVAL.MONTH} onChange={handleClickInterval} value={chosenInterval}>
              <FormControlLabel value={BILLING_INTERVAL.MONTH} control={<Radio />} 
                label={`$${NEW_TIER_DETAILS[chosenTierKey]?.monthlyPrice}/month`} sx={{marginRight: "24px"}}/>
              <FormControlLabel value={BILLING_INTERVAL.YEAR} control={<Radio />} 
                label={`$${NEW_TIER_DETAILS[chosenTierKey]?.annualPrice}/year`} sx={{marginRight: "24px"}}/>
            </RadioGroup>
          </FormControl>
          {dialogError && 
            <Typography variant='body1' color="error">
              {dialogError}
            </Typography>
          }
        </div>
      </CabModal>
      <CabModal 
        open={cancelDialogOpen}
        onClose={closeCancelDialog}
        title="Cancel Subscription"
        aria-labelledby='Cancel Subscription Dialog'
        aria-describedby='Cancel Subscription Dialog'
        actionButtons={
          <DialogButtonContainer>
            
            {!disableCancelButtons &&
              <>
                <StyledButton 
                  disabled={disableCancelButtons}
                  onClick={() => setCancelDialogOpen(false)}
                >
                  Stay on my plan
                </StyledButton>
                <StyledPopupButton
                  id={cancelationSurveyId}
                  hidden={ accountOwner ? { 
                    name: `${accountOwner.first_name} ${accountOwner.last_name}`,
                    email: accountOwner.email,
                    disabled: confirmButtonDisabled.toString(),
                    autocancel: "1",
                  } : {
                    disabled: confirmButtonDisabled.toString(),
                    autocancel: "1",
                  }}
                  autoClose={2000}
                  onSubmit={async () => {
                    try {
                      await onChangeSubscription(NEW_TIER.BASIC, chosenInterval, null, null, false, null);
                      setDisableCancelButtons(true);
                      setCancelDialogOpen(false);
                    } catch (e) {
                      // pass
                    }
                  }}
                >
                  Cancel my plan
                </StyledPopupButton>
              </>
            }
            
          </DialogButtonContainer>
        }
      >
        <div>
          <Box sx={{marginBottom: "16px"}}>
            {'Are you sure you want to cancel your subscription?'}
          </Box>
          <Typography variant='body2'>
            {`You will still have access to the features of your current tier \
            for the remainder of your current billing cycle. After that, you will only have access to \
            Cabinet's Basic tier features and the Cabinet Community.`}
          </Typography>
          {dialogError && 
            <Typography variant='body1' color="error" sx={{marginTop: 2}} >
              {dialogError}
            </Typography>
          }
        </div>
      </CabModal>
      <Dialog 
        open={redirectDialogOpen}
        maxWidth={'xs'}
        fullWidth={true}
        aria-labelledby='Redirect to Stripe Dialog'
        aria-describedby='Redirect to Stripe Dialog'
      >
        <Typography variant='h4' sx={{padding: "24px"}}>
          Redirecting to Stripe for secure checkout...
        </Typography>
      </Dialog>
    </>
  );
};

export default Subscription;

const StyledPopupButton = styled(PopupButton, {label: "StyledPopupButton"})(({theme, hidden}) => ({
  minWidth: 140,
  fontSize: 14,
  fontWeight: 500,
  fontFamily: theme.typography.fontFamily,
  lineHeight: "20px",
  padding: "6px 16px",
  borderRadius: 4,
  backgroundColor: theme.palette.error.main,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: hidden?.disabled !== "false"
    ?  theme.palette.action.disabledBackground
    : theme.palette.error.main,
  color: hidden?.disabled !== "false"
    ? theme.palette.action.disabled
    : theme.palette.error.contrastText,
}));

const StyledButton = styled(CabButton, {label: "Styledbutton"})({
  minWidth: "140px",
  fontSize: "14px",
});

const LineItem = styled("div", {label: "LineItem"})({
  padding: "12px",
  borderTop: `2px solid ${colors.black50}`,
});

const LineItemGroupTitle = styled("div", {label: "LineItemGroupTitle"})({
  padding: "12px",
  fontSize: "14px",
  fontWeight: "bold",
});

const LineItemGrid = styled(Grid, {label: "LineItemGrid"})({
  padding: "12px",
  borderTop: `2px solid ${colors.black50}`,
});

const LinkText = styled(Typography, {label: "LinkText"})({
  color: colors.navyPrimary,
  textDecoration: "underline",
  "&:hover": {
    cursor: "pointer",
  }
});

const DialogButtonContainer = styled("div", {label: "DialogButtonContainer"})({
  display: "flex",
  justifyContent: "space-between",
  width: "100%"
});